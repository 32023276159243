import React from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Entrust.module.css";
import { Typography } from "@mui/material";

const Entrust = () => {

  return (
    <Grid container justifyContent="center">
      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }}>
        <Grid
          container
          justifyContent="center"
          rowSpacing={2}
          columnSpacing={2}
        >
          <Grid size={{ xs: 12, sm: 12, md: 5, lg: 5, xl: 5 }}>
            <Grid container>
              <Grid>
                <Typography className={styles.entrustTitle}>
                  Why entrust your needs to us?
                </Typography>
              </Grid>
              <Grid>
                <Typography className={styles.entrustDescription}>
                  Crafting exceptional products with passion and love.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 7, lg: 7, xl: 7 }}>
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid size={{ xs: 12, sm: 8 }}>
                <Typography className={styles.entrustButton}>
                  React, HTML, CSS Development
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Typography className={styles.entrustButton}>
                  UI/UX Design
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 7 }}>
                <Typography className={styles.entrustButton}>
                  End to End Development
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 5 }}>
                <Typography className={styles.entrustButton}>
                  Team Extension
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Typography className={styles.entrustButton}>
                  Web Development
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Typography className={styles.entrustButton}>
                  Mobile Development
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Entrust;
