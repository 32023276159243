import React from "react";
import Header from "./Header/Header";
import WhatWeDo from "./WhatWeDo/WhatWeDo";
import Home from "./Home/Home";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import Entrust from "./Entrust/Entrust";
import Services from "./Services/Services";
import Factor from "./Factor/Factor";
import { useBreakpoints } from "../Utils/ResponsiveBreakpoints";
import Testimonials from "./Testimonials/Testimonials";
import Products from "./Products/Products";
import IndustryList from "./IndustryList/IndustryList";
import Faq from "./Faq/Faq";
import ContactUs from "./ContactUs/ContactUs";
import Footer from "./Footer/Footer";
import Copyright from "./Copyright/Copyright";
import CrossLine from "./CrossLine/CrossLine";

const HomePage = () => {
  const { IsXS, IsSM } = useBreakpoints();
  const scrollToSection = (sectionId) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <Header onScrollToSection={scrollToSection}/>
      <Home />
      <CrossLine />
      <WhatWeDo />
      <CompanyInfo />
      <Entrust />
      <Services />
      {(IsXS || IsSM) ? '' : <Factor />}
      <Testimonials />
      <Products />
      <IndustryList />
      <Faq />
      <ContactUs />
      <Footer />
      <Copyright />
    </div>
  );
};

export default HomePage;
