export default {
  GET_HEADERS_REQUEST: "GET_HEADERS_REQUEST",
  GET_HEADERS_SUCCESS: "GET_HEADERS_SUCCESS",
  GET_HEADERS_FAILURE: "GET_HEADERS_FAILURE",

  GET_HOME_REQUEST: "GET_HOME_REQUEST",
  GET_HOME_SUCCESS: "GET_HOME_SUCCESS",
  GET_HOME_FAILURE: "GET_HOME_FAILURE",

  GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_SERVICES_FAILURE: "GET_SERVICES_FAILURE",

  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
  
  GET_FOOTERS_REQUEST: 'GET_FOOTERS_REQUEST',
  GET_FOOTERS_SUCCESS: 'GET_FOOTERS_SUCCESS',
  GET_FOOTERS_FAILURE: 'GET_FOOTERS_FAILURE',

  GET_WHO_WE_ARE_REQUEST: 'GET_WHO_WE_ARE_REQUEST',
  GET_WHO_WE_ARE_SUCCESS: 'GET_WHO_WE_ARE_SUCCESS',
  GET_WHO_WE_ARE_FAILURE: 'GET_WHO_WE_ARE_FAILURE',

  GET_BLOGS_REQUEST: 'GET_BLOGS_REQUEST',
  GET_BLOGS_SUCCESS: 'GET_BLOGS_SUCCESS',
  GET_BLOGS_FAILURE: 'GET_BLOGS_FAILURE',

  GET_BLOG_PREVIEW_REQUEST: 'GET_BLOG_PREVIEW_REQUEST',
  GET_BLOG_PREVIEW_SUCCESS: 'GET_BLOG_PREVIEW_SUCCESS',
  GET_BLOG_PREVIEW_FAILURE: 'GET_BLOG_PREVIEW_FAILURE',

};
