import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Testimonials.module.css";
import weStyles from "../WhatWeDo/WhatWeDo.module.css";
import factorStyles from "../Factor/Factor.module.css";
import { Typography } from "@mui/material";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";
import rightArrow from "../../assets/rightArrow.svg";
import leftArrow from "../../assets/leftArrow.svg";

const Testimonials = () => {
  const { IsXS, IsSM, IsLG, IsXL } = useBreakpoints();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };
  const testimonials = [
    {
      name: "Shree Prasadam ",
      info: "DEFX has completely transformed our bakery with their PREST software and website. Streamlined operations and a beautiful online presence have made a huge difference!",
    },
    {
      name: "Seagull GNSS",
      info: "DEFX provided the perfect solution to make our existing hardware work more efficiently and effectively.",
    },
    {
      name: "Harsh Vakil",
      info: "We are so impressed with VASTU web. They have build reliable platform and DEFX's support is top-notch.",
    },
  ];

  const times = new Array(5).fill(null);

  return (
    <Grid
      container
      justifyContent="center"
      className={styles.container}
      marginY={IsXS || IsSM ? "" : 20}
    >
      {IsXS || IsSM ? (
        " "
      ) : (
        <Grid size={{md: 4, lg: 4, xl: 4.5, xxl:5}}>
          <Grid container justifyContent="space-between">
            {times.map((_, index) => {
              const opacityValue = 0.3 - index * 0.06;
              return (
                <Grid size={2.4} key={index}>
                  <Typography
                    className={styles.testimonialsTextData}
                    sx={{ opacity: opacityValue, pointerEvents: "none" }}
                  >
                    Testimonials
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      <Grid
        size={{ xs: 11, sm: 8, md: 4, lg: 4, xl: 3, xxl:2 }}
        className={styles.testimonialsContainer}
        paddingY={IsXS || IsSM ? '' : 2}
      >
        <Grid
          container
          justifyContent="center"
          rowSpacing={IsXL || IsSM || IsXS ? 2: 1}
          columnSpacing={2}
        >
          <Grid size={12} className={styles.testimonialsContainer}>
            <div className={styles.textWrapper}>
              <Typography className={styles.testimonialsText}>
                Testimonials
              </Typography>
            </div>
          </Grid>
          <Grid size={10}>
            <Typography className={styles.testimonialHeading}>
              REAL STORIES FROM REAL PEOPLE
            </Typography>
          </Grid>
          <Grid size={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              columnSpacing={0.7}
            >
              <Grid size={1} onClick={handlePrevious} textAlign="right">
                <img src={leftArrow} style={{ cursor: "pointer" }} />
              </Grid>
              <Grid size={10} className={factorStyles.gradiantColor}>
                <Grid container rowSpacing={2}>
                  <Grid size={12}>
                    <Typography className={styles.testimonialsDataTitle}>
                      {testimonials[currentIndex].name}
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    {" "}
                    <Typography className={styles.testimonialsDataDescription}>
                      {testimonials[currentIndex].info}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={1} onClick={handleNext} textAlign="left">
                <img src={rightArrow} style={{ cursor: "pointer" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {IsXS || IsSM ? (
        " "
      ) : (
        <Grid size={{md: 4, lg: 4, xl: 4.5, xxl:5}}>
          <Grid container justifyContent="space-between">
            {times.map((_, index) => {
              const opacityValue = 0.06 + index * 0.06;
              return (
                <Grid size={2.4} key={index}>
                  <Typography
                    className={styles.testimonialsTextData2}
                    sx={{ opacity: opacityValue, pointerEvents: "none" }}
                  >
                    Testimonials
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Testimonials;
