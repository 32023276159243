import constants from "../Utils/constants";

const initialState = {
  isServicesLoading: false,
  servicesData: [],
};

export const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SERVICES_REQUEST:
      return {
        ...state,
        isServicesLoading: true,
      };
    case constants.GET_SERVICES_SUCCESS:
      return {
        ...state,
        isServicesLoading: false,
        servicesData: action.payload,
      };
    case constants.GET_SERVICES_FAILURE:
      return {
        ...state,
        isServicesLoading: false,
      };

    default:
      return state;
  }
};
