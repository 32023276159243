import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import whoWeAreStyles from "../WhoWeAre/WhoWeAre.module.css";
import { getWhoWeAre } from "../../Actions/whoWeAreAction";
import { Box, Typography } from "@mui/material";
import rightArrow from "../../assets/rightArrow2.svg";
import leftArrow from "../../assets/leftArrow2.svg";
import accountIcon from "../../assets/accountIcon.jpg";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";
import styles from "./Team.module.css";
import { useDimensions } from "../../CommonMethods";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import "swiper/css";
import "swiper/css/pagination";
import { useTheme } from "@emotion/react";

const Team = () => {
  const { IsXS, IsSM, IsMD, IsLG, IsXL, IsXXL } = useBreakpoints();
  const { themeMode, toggleTheme } = useTheme();
  const { width } = useDimensions();
  const dispatch = useDispatch();
  const { whoWeAreData, isWhoWeAreLoading } = useSelector(
    (state) => state.whoWeAre
  );
  const data = whoWeAreData?.data || [];
  const dataLength = data.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [hasError, setHasError] = useState(false);


  const handleSwiper = (swiper) => {
    setSwiperInstance(swiper);
  };

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };
  // useEffect(() => {
  //   console.log("Active index changed:", activeIndex);
  // }, [activeIndex]);

  useEffect(() => {
    dispatch(getWhoWeAre());
  }, [dispatch]);

  if (isWhoWeAreLoading) {
    return <div></div>;
  }

  if (!dataLength) {
    return <div>No data available.</div>;
  }

  return (
    <Grid container justifyContent="center">
      <Grid size={12} mt={8}>
        <Grid container justifyContent="center" rowSpacing={2}>
          <Grid size={12} className={whoWeAreStyles.techStackContainer}>
            <div className={whoWeAreStyles.textWrapper}>
              <Typography className={whoWeAreStyles.techStackText}>
                Maestros
              </Typography>
            </div>
          </Grid>
          <Grid size={12}>
            <Typography className={whoWeAreStyles.teamHighlightsTitle}>
              OUR TEAM
            </Typography>
          </Grid>
          <Grid
            size={12}
            className={whoWeAreStyles.testimonialsContainer}
            paddingY={IsXS || IsSM ? "" : 2}
          >
            <Grid
              container
              justifyContent="center"
              rowSpacing={IsXL || IsSM || IsXS ? 2 : 1}
              columnSpacing={2}
            >
              <Grid size={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  columnSpacing={0.7}
                >
                  <Grid size={12} mt={7} className={styles.itemCenter}>
                    <Swiper
                      slidesPerView={
                        IsXS
                          ? 1.2
                          : IsSM
                          ? 1.9
                          : IsMD
                          ? 2.5
                          : IsLG
                          ? 3.2
                          : IsXL
                          ? 5
                          : 6
                      }
                      centeredSlides={true}
                      // spaceBetween={30}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                        clickable: true,
                      }}
                      onSlideChange={handleSlideChange}
                      loop={false}
                      autoplay={false}
                      freeMode={false}
                      className={`${styles.mySwiper}`}
                      modules={[Navigation]}
                      allowTouchMove={false}
                      onSwiper={handleSwiper}
                      speed={1000}
                      slideActiveClass={styles.swiperSlideActive}
                      style={{ height: "400px" }}
                    >
                      {IsXS || IsSM ? '': (
                        <Grid
                          container
                          justifyContent="end"
                          columnSpacing={2}
                          style={{
                            position: "absolute",
                            top: "10px",
                            zIndex: 10,
                            width: "85%",
                          }}
                        >
                          <Grid
                            className="swiper-button-next"
                            style={{
                              opacity: swiperInstance?.isEnd ? 0.5 : 1,
                              pointerEvents: swiperInstance?.isEnd
                                ? "none"
                                : "auto",
                            }}
                          >
                            <img
                              src={leftArrow}
                              style={{ cursor: "pointer" }}
                            />
                          </Grid>
                          <Grid
                            className="swiper-button-prev"
                            style={{
                              opacity: swiperInstance?.isBeginning ? 0.5 : 1,
                              pointerEvents: swiperInstance?.isBeginning
                                ? "none"
                                : "auto",
                            }}
                          >
                            <img
                              src={rightArrow}
                              style={{ cursor: "pointer" }}
                            />
                          </Grid>
                        </Grid>
                      )}

                      {dataLength &&
                        data.map((item, index) => {
                          const isActive = activeIndex === index;
                          const imageUrl =
                            item?.attributes?.Hero?.Media[0]?.Image?.data
                              ?.attributes?.url;
                          return (
                            <SwiperSlide
                              key={index}
                              className={`${styles.swiperSlideContent} ${
                                isActive
                                  ? styles.activeSlide
                                  : styles.inactiveSlide
                              }`}
                              style={{
                                opacity: isActive ? 1 : 0.5,
                                marginBottom: isActive ? "100px" : "0",
                                marginTop: !isActive ? "50px" : "0",
                                transition:
                                  "opacity 1000ms ease, margin 1000ms ease",
                              }}
                            >
                              <Grid
                                height={
                                  width > 651
                                    ? 270
                                    : width > 650
                                    ? 250
                                    : width > 300
                                    ? 210
                                    : ""
                                }
                                className={
                                  isActive
                                    ? styles.gradiantColor
                                    : styles.indexes
                                }
                                mt={5}
                              >
                                <Grid container columnSpacing={1} wrap="nowrap">
                                  <Grid size={6}>
                                    <Grid
                                      container
                                      rowSpacing={1}
                                      justifyContent="center"
                                    >
                                      <Grid
                                        size={12}
                                        className={styles.itemCenter}
                                      >
                                        {!hasError ? (
                                          <img
                                            src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                                            className={`${styles.image} ${
                                              isActive
                                                ? styles.imageActive
                                                : styles.imageInactive
                                            }`}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = accountIcon;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={accountIcon}
                                            className={styles.image}
                                          />
                                        )}
                                      </Grid>
                                      <Grid size={12}>
                                        <Typography
                                          className={styles.teamTitle}
                                          textAlign="center"
                                        >
                                          {item?.attributes?.Hero?.Title}
                                        </Typography>
                                      </Grid>
                                      <Grid size={12}>
                                        <Typography
                                          className={styles.teamHeading}
                                        >
                                          {item?.attributes?.Title}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid size={6} className={styles.itemCenter}>
                                    {" "}
                                    <Typography className={styles.teamHeading}>
                                      {
                                        item?.attributes?.Hero?.Description[0]
                                          ?.children[0]?.text
                                      }
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </SwiperSlide>
                          );
                        })}
                        {IsXS || IsSM ? (
                        <Grid
                          container
                          justifyContent="center"
                          columnSpacing={2}
                          style={{
                            position: "absolute",
                            top: "300px",
                            zIndex: 10,
                            width: "100%",
                            marginTop:'70px'
                          }}
                        >
                          <Grid
                            className="swiper-button-next"
                            style={{
                              opacity: swiperInstance?.isEnd ? 0.5 : 1,
                              pointerEvents: swiperInstance?.isEnd
                                ? "none"
                                : "auto",
                            }}
                          >
                            <img
                              src={leftArrow}
                              style={{ cursor: "pointer" }}
                              tabIndex={-1}
                            />
                          </Grid>
                          <Grid
                            className="swiper-button-prev"
                            style={{
                              opacity: swiperInstance?.isBeginning ? 0.5 : 1,
                              pointerEvents: swiperInstance?.isBeginning
                                ? "none"
                                : "auto",
                            }}
                          >
                            <img
                              src={rightArrow}
                              style={{ cursor: "pointer",outline: "none" }}
                            />
                          </Grid>
                        </Grid>
                      ) :''}
                    </Swiper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Team;
