import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Factor.module.css";
import { Typography } from "@mui/material";
import { Logo2 } from "../../Utils/SvgImage";
import xFactor from "../../assets/xFactor.svg";
import heroku from "../../assets/heroku.svg";
import heroku2 from "../../assets/heroku2.svg";
import aws from "../../assets/aws.svg";
import slack from "../../assets/slack.svg";
import asana from "../../assets/asana.svg";
import github from "../../assets/github.svg";
import { useTheme } from "../../ThemeContext";

const Factor = () => {
  const { themeMode, toggleTheme } = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);

  const data = [
    { title: "Custom Software Development" },
    { title: "UI/UX Design" },
    { title: "QA & Software Testing" },
    { title: "Software Product Development" },
    { title: "Mobile Application Development" },
    { title: "Maintenance & Support" },
    { title: "Software Project Recovery" },
    { title: "CMS Solutions" },
    { title: "ERP Solutions" },
    { title: "eCommerce Solutions" },
    { title: "Technology Consulting" },
  ];

  const apps = [
    {
      title: "Heroku",
      description: "Enabling Easy Deployments",
      image: themeMode === "light" ? heroku2 : heroku,
    },
    {
      title: "AWS",
      description: "Empowering Cloud Solutions",
      image: aws,
    },
    {
      title: "GitHub",
      description: "Efficient Code Collaboration",
      image: github,
    },
    {
      title: "Asana",
      description: "Effortless Task Management",
      image: asana,
    },
    {
      title: "Slack",
      description: "Simplifying Teams Communication",
      image: slack,
    },
  ];

  useEffect(() => {
    if (!data || data?.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <Grid container justifyContent="center">
      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }}>
        <Grid
          container
          justifyContent="center"
          rowSpacing={1}
          columnSpacing={2}
        >
          <Grid size={12}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid size={3.5} className={styles.factorSubContainer}>
                <div className={styles.logoWrapper}>
                  <Logo2 width={90} height={70} />
                </div>
              </Grid>
              <Grid size={4.5} className={styles.factorDefine}>
                <div className={styles.textWrapper}>
                  <Typography className={styles.factorText}>
                    Define your X factor
                  </Typography>
                </div>
              </Grid>
              <Grid size={4} className={styles.factorData}>
                {data?.map((item, index) => {
                  if (index === currentIndex) {
                    return (
                      <Typography key={index} className={styles.factorServices}>
                        {item?.title}
                      </Typography>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid size={5} className={styles.gradiantColor}>
                <Typography className={styles.factorCraft}>
                  "Devoted to the Craft: Meticulously Crafting Exceptional
                  Products with Unmatched Passion and Expertise."
                </Typography>
              </Grid>
              <Grid size={7} className={styles.gradiantColor} sx={{display:'flex', justifyContent:'center'}}>
                <Grid container alignItems="center" columnSpacing={1}>
                  <Grid size={6}>
                    <img src={xFactor} />
                  </Grid>
                  <Grid size={6}>
                    <Grid container rowSpacing={3}>
                      <Grid size={12}>
                        <Typography className={styles.factorProject}>
                          Have a Project in Mind?
                        </Typography>
                      </Grid>
                      <Grid size={12} className={styles.factorButton}>
                        <Typography
                          className={styles.factorDefx}
                          component="a"
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=hello@defx.in"
                          target="_blank"
                          sx={{ textDecoration: "none", color: "inherit" }}
                        >
                          hello@defx.in
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12}>
            <Grid container columnSpacing={1}>
              {apps.map((item, index) => {
                return (
                  <Grid key={index} className={styles.appsContainer} size={2.4}>
                    <Grid
                      container
                      rowSpacing={1}
                      className={styles.appsMainContainer}
                    >
                      <Grid size={12}>
                        <Grid container justifyContent="space-between">
                          <Grid>
                            <Typography className={styles.appsTitle}>
                              {item.title}
                            </Typography>
                          </Grid>
                          <Grid className={styles.appsIcon}>
                            <img src={item.image} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid size={8}>
                        <Typography className={styles.appsDescription}>
                          {item.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Factor;
