import React from "react";
import HomePage from "./Components/HomePage";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import WhoWeArePage from "./Components/WhoWeArePage";
import BlogsPage from "./Components/BlogsPage";
import BlogsTextPage from "./Components/BlogsTextPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/whoweare" element={<WhoWeArePage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blogs/:slug" element={<BlogsTextPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
