import API from "../Utils/API";

export const getHome = () => (dispatch) => {
  dispatch({ type: "GET_HOME_REQUEST" });
  API.get('api/homes?populate[Hero][populate][0]=Internal_Link&populate[Hero][populate][1]=custom_Url&populate[Hero][populate][2]=Media.Image&populate[Hero][populate][3]=Add_Column&populate[Content][populate][4]=Internal_Links&populate[Content][populate][5]=Custom_Url&populate[Content][populate][6]=Content_Layout&populate[Content][populate][7]=Archive&populate[Content][populate][8]=Media.Image&populate[Content][populate][9]=Call_To_Action')
    .then((response) => {
      dispatch({
        type: "GET_HOME_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_HOME_FAILURE",
        payload: error.response?.data || "An error occurred",
      });
    });
};
