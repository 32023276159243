import { DiscussionEmbed } from 'disqus-react';
import { useTheme } from '../ThemeContext';

export default function CommentEmbed({ shortname, config }){
  const { themeMode, toggleTheme } = useTheme();
  return (
    <div size={12}>
      <DiscussionEmbed shortname={shortname} config={config} style={{ backgroundColor: themeMode === 'light' ? '#fbfbfb' : '#191919', color:themeMode === 'light' ? '#fbfbfb' : '#191919', fontfamily:'Circular-900'}}/>
    </div>
  );
};

