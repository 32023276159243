import React from "react";
import Header from "./Header/Header";
import { useBreakpoints } from "../Utils/ResponsiveBreakpoints";
import Footer from "./Footer/Footer";
import Copyright from "./Copyright/Copyright";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import Team from "./Team/Team";
import ContactUs from "./ContactUs/ContactUs";
import Awards from "./Awards/Awards";
import Blogs from "./Blogs/Blogs";
import BlogsText from "./BlogsText/BlogsText";

const BlogsTextPage = () => {
  const { IsXS, IsSM } = useBreakpoints();
  const scrollToSection = (sectionId) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header onScrollToSection={scrollToSection} />
      <BlogsText />
      <ContactUs />
      <Footer />
      <Copyright />
    </div>
  );
};

export default BlogsTextPage;
