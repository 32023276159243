import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./CrossLine.module.css";
import { Typography } from "@mui/material";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const CrossLine = () => {
  const { IsXS,IsMD, IsLG, IsXL, IsXXL } = useBreakpoints();
  const LinesData = [
    "• React",
    "• React Native",
    "• Node",
    "• Ruby on Rails",
    "• Angular",
    "• Jenkins",
    "• MongoDB",
    "• PostgreSQL",
    "• Kubernetes",
    "• Docker",
    "• Portainer",
    "• iOS",
    "• Android",
  ];

  return (
    <div style={{ overflowX: "hidden", width: "100vw", position: "relative" }}>
      <div
        style={{
          marginTop: IsXS ? '160px' :'200px',
          marginBottom: IsXS ? '160px' : "200px",
          overflowX: "hidden",
          display:'flex',
          alignItems:'center'
        }}
      >
        <Grid
          container
          justifyContent={IsLG || IsXL || IsXXL ? "space-around" : ""}
          sx={{
            display: "flex",
            overflowX: "hidden",
            whiteSpace: "nowrap",
          }}
          wrap="nowrap"
          className={styles.crossLineContainer}
        >
          {LinesData.map((item, index) => (
            <Grid key={index} sx={{ flexShrink: 0, marginRight: 3 }}>
              <Typography className={styles.crossLineTitle}>{item}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          justifyContent={IsLG || IsXL || IsXXL? "space-around" : ""}
          sx={{
            display: "flex",
            overflowX: "hidden",
            whiteSpace: "nowrap",
          }}
          wrap="nowrap"
          className={styles.crossLineContainer2}
        >
          {LinesData.slice()
            .reverse()
            .map((item, index) => (
              <Grid key={index} sx={{ flexShrink: 0, marginRight: 3 }}>
                <Typography className={styles.crossLineTitle}>
                  {item}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default CrossLine;
