import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import { CircularProgress, Divider, Typography } from "@mui/material";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";
import styles from "./Blogs.module.css";
import { useDimensions } from "../../CommonMethods";
import "swiper/css";
import "swiper/css/pagination";
import { useTheme } from "../../ThemeContext";
import { getBlogs } from "../../Actions/blogActions";
import {
  ArrowIcon,
  CalendarIcon,
  PenIcon,
  ReadTimeIcon,
} from "../../Utils/SvgImage";
import { data, Link, useNavigate } from "react-router-dom";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const Blogs = () => {
  const { IsXS, IsSM, IsMD, IsLG, IsXL, IsXXL } = useBreakpoints();
  const { themeMode, toggleTheme } = useTheme();
  const { width } = useDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogData, isBlogsLoading } = useSelector((state) => state.blogs);
  const [visibleRange, setVisibleRange] = useState({ start: 3, end: 8 });

  const loadMoreBlogs = () => {
    setVisibleRange((prevRange) => ({
      ...prevRange,
      end: prevRange.end + 5,
    }));
  };

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  if (isBlogsLoading) {
    return (
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl: 5 }} mt={8}>
        <Grid container rowSpacing={2}>
          <Grid>
            <Typography className={styles.blogMainTitle}>Top Blogs</Typography>
          </Grid>
          <Grid size={12} mb={IsXS ? 8 : ""}>
            <Grid container columnSpacing={2} rowSpacing={IsSM ? "" : 6}>
              <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                <Grid container>
                  {blogData.data?.length > 0 &&
                    blogData?.data?.map((value, index) => {
                      const imageUrl =
                        value?.attributes?.Hero?.Media[0]?.Image?.data
                          ?.attributes?.url;

                      const publishDate = value?.attributes?.Publish_Date;
                      const date = publishDate
                        ? new Date(publishDate).toISOString().split("T")[0]
                        : null;
                      const time = publishDate
                        ? new Date(publishDate)
                            .toISOString()
                            .split("T")[1]
                            .split(":")[1]
                        : null;
                      const title = value?.attributes?.Hero?.Title;
                      const author = value?.attributes?.Author;
                      const url = value?.attributes?.Slug;
                      const description = value?.attributes?.Hero?.Description;

                      const timeToRead = value?.attributes?.Time_to_read;

                      const allText = description
                        .flatMap(
                          (block) =>
                            block.children?.map((child) => child.text) || []
                        )
                        .join(" ");
                      const truncatedText =
                        allText.split(" ").slice(0, 15).join(" ") + " ...";
                      const truncatedContent = [
                        {
                          type: "paragraph",
                          children: [{ text: truncatedText, type: "text" }],
                        },
                      ];

                      if (index === 0) {
                        return (
                          <Grid key={index} size={12}>
                            <Grid container rowSpacing={1}>
                              <Grid size={12}>
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                                  alt="blogs-image"
                                  width="100%"
                                  height={
                                    IsXL
                                      ? "400px"
                                      : IsMD
                                      ? "300px"
                                      : IsXS
                                      ? "250px"
                                      : "350px"
                                  }
                                  style={{
                                    borderRadius: "3px",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => navigate(url)}
                                />
                              </Grid>
                              <Grid size={12}>
                                <Grid container>
                                  <Grid size={12}>
                                    <Typography className={styles.categoryItem}>
                                      {timeToRead} mins to read
                                    </Typography>
                                  </Grid>
                                  <Grid size={12}>
                                    <Typography
                                      className={styles.blogTitle}
                                      onClick={() => navigate(url)}
                                    >
                                      {title}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid size={12}>
                                <Grid container rowSpacing={2}>
                                  <Grid size={12}>
                                    <Grid container>
                                      <Typography className={styles.author}>
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: "5px",
                                          }}
                                        >
                                          <PenIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                        </span>
                                        {author}
                                      </Typography>
                                      <Typography
                                        className={styles.author}
                                        style={{
                                          marginLeft: "20px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: "5px",
                                          }}
                                        >
                                          {" "}
                                          <CalendarIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                        </span>
                                        {date}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid onClick={() => navigate(url)}>
                                    <BlocksRenderer
                                      content={truncatedContent}
                                      blocks={{
                                        paragraph: ({ children }) => (
                                          <Typography
                                            className={styles.blogDescription}
                                          >
                                            {children}
                                          </Typography>
                                        ),
                                        heading: ({ children, level }) => (
                                          <Grid container mb={1}>
                                            <Grid size={12}>
                                              <Typography
                                                variant={`h${level || 1}`}
                                                className={styles.blogHeading}
                                                style={{
                                                  fontSize:
                                                    [
                                                      "32px",
                                                      "28px",
                                                      "24px",
                                                      "20px",
                                                      "18px",
                                                      "16px",
                                                    ][level - 1] || "32px",
                                                }}
                                              >
                                                {children}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        ),
                                        link: ({ children, url }) => (
                                          <Link
                                            to={url}
                                            style={{ color: "white" }}
                                            className={styles.blogDescription}
                                          >
                                            {children}
                                          </Link>
                                        ),
                                      }}
                                      modifiers={{
                                        bold: ({ children }) => (
                                          <span
                                            className={
                                              styles.blogDescriptionBold
                                            }
                                          >
                                            {children}
                                          </span>
                                        ),
                                        italic: ({ children }) => (
                                          <span
                                            className={styles.blogDescription}
                                          >
                                            {children}
                                          </span>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  {IsXS ? (
                                    <Grid size={12}>
                                      <Typography
                                        className={styles.blogDescription}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => navigate(url)}
                                      >
                                        Read more{" "}
                                        <span
                                          style={{
                                            marginLeft: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ArrowIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                        </span>
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Grid>
              {IsSM ? (
                <Grid size={12} my={4}>
                  <Divider sx={{ borderColor: "#ccc" }} />
                </Grid>
              ) : (
                ""
              )}
              <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                <Grid container direction="column" sx={{ height: "100%" }}>
                  {blogData.data?.length > 0 &&
                    blogData?.data?.map((value, index) => {
                      const imageUrl =
                        value?.attributes?.Hero?.Media[0]?.Image?.data
                          ?.attributes?.url;
                      const publishDate = value?.attributes?.Publish_Date;
                      const date = publishDate
                        ? new Date(publishDate).toISOString().split("T")[0]
                        : null;

                      const title = value?.attributes?.Hero?.Title;
                      const author = value?.attributes?.Author;
                      const url = value?.attributes?.Slug;
                      const timeToRead = value?.attributes?.Time_to_read;
                      const description = value?.attributes?.Hero?.Description;
                      const allText = description
                        .flatMap(
                          (block) =>
                            block.children?.map((child) => child.text) || []
                        )
                        .join(" ");
                      const truncatedText =
                        allText.split(" ").slice(0, 15).join(" ") + " ...";
                      const truncatedContent = [
                        {
                          type: "paragraph",
                          children: [{ text: truncatedText, type: "text" }],
                        },
                      ];

                      if (index === 1 || index === 2) {
                        return (
                          <Grid
                            size={12}
                            sx={{ height: "50%" }}
                            pt={!(IsXS || IsSM) && index === 2 ? 2 : ""}
                          >
                            <Grid
                              container
                              spacing={IsXS || IsSM ? "" : 2}
                              sx={{ height: "100%" }}
                              mt={
                                IsXS && index === 2
                                  ? 6
                                  : IsSM && index === 2
                                  ? 2
                                  : ""
                              }
                            >
                              <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                                  alt="blogs-image"
                                  width="100%"
                                  height={
                                    IsXS ? "250px" : IsSM ? "350px" : "100%"
                                  }
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "3px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => navigate(url)}
                                />
                              </Grid>
                              <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                                <Grid
                                  container
                                  rowSpacing={IsXS || IsSM ? 2 : 1}
                                >
                                  <Grid size={12}>
                                    <Grid container>
                                      <Grid size={12}>
                                        <Typography
                                          className={styles.timeToRead}
                                        >
                                          {timeToRead} mins to read
                                        </Typography>
                                      </Grid>
                                      <Grid>
                                        <Typography
                                          className={styles.blogTitleSec}
                                          onClick={() => navigate(url)}
                                        >
                                          {title}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {IsXS || IsSM ? (
                                    <Grid size={12}>
                                      <Grid container>
                                        <Typography
                                          className={styles.authorSec}
                                        >
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "5px",
                                            }}
                                          >
                                            <PenIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                          </span>
                                          {author}
                                        </Typography>{" "}
                                        <Typography
                                          className={styles.authorSec}
                                          style={{
                                            marginLeft: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {" "}
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "5px",
                                            }}
                                          >
                                            {" "}
                                            <CalendarIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                          </span>
                                          {date}
                                        </Typography>{" "}
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  <Grid onClick={() => navigate(url)}>
                                    <BlocksRenderer
                                      content={truncatedContent}
                                      blocks={{
                                        paragraph: ({ children }) => (
                                          <Typography
                                            className={
                                              styles.blogDescriptionSec
                                            }
                                          >
                                            {children}
                                          </Typography>
                                        ),
                                        link: ({ children, url }) => (
                                          <Link
                                            to={url}
                                            style={{ color: "white" }}
                                            className={styles.blogDescriptionSec}
                                          >
                                            {children}
                                          </Link>
                                        ),
                                      }}
                                      modifiers={{
                                        bold: ({ children }) => (
                                          <span
                                            className={
                                              styles.blogDescriptionBold
                                            }
                                          >
                                            {children}
                                          </span>
                                        ),
                                        italic: ({ children }) => (
                                          <span
                                            className={styles.blogDescription}
                                          >
                                            {children}
                                          </span>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  {!(IsXS || IsSM) ? (
                                    <Grid size={12}>
                                      <Grid container>
                                        <Typography
                                          className={styles.authorSec}
                                        >
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "5px",
                                            }}
                                          >
                                            <PenIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                          </span>
                                          {author}
                                        </Typography>{" "}
                                        <Typography
                                          className={styles.authorSec}
                                          style={{
                                            marginLeft: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {" "}
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "5px",
                                            }}
                                          >
                                            {" "}
                                            <CalendarIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                          </span>
                                          {date}
                                        </Typography>{" "}
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  {IsXS ? (
                                    <Grid size={12}>
                                      <Typography
                                        className={styles.blogDescription}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => navigate(url)}
                                      >
                                        Read more{" "}
                                        <span
                                          style={{
                                            marginLeft: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ArrowIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                        </span>
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>
                              {IsSM
                                ? index !== blogData.data?.length - 1 && (
                                    <Grid size={12} my={4}>
                                      <Divider sx={{ borderColor: "#ccc" }} />
                                    </Grid>
                                  )
                                : ""}
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!(IsXS || IsSM) ? (
            <Grid size={12} my={10}>
              <Divider sx={{ borderColor: "#ccc" }} />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid container rowSpacing={2}>
          <Grid>
            <Typography className={styles.blogMainTitle}>
              Latest Blogs
            </Typography>
          </Grid>

          <Grid size={12}>
            <Grid container rowSpacing={IsXS ? 6 : ""}>
              {blogData.data?.length > 0 &&
                blogData?.data
                  ?.slice(visibleRange.start, visibleRange.end)
                  .map((value, index) => {
                    const imageUrl =
                      value?.attributes?.Hero?.Media[0]?.Image?.data?.attributes
                        ?.url;
                    const publishDate = value?.attributes?.Publish_Date;
                    const date = publishDate
                      ? new Date(publishDate).toISOString().split("T")[0]
                      : null;

                    const title = value?.attributes?.Hero?.Title;
                    const author = value?.attributes?.Author;
                    const url = value?.attributes?.Slug;
                    const timeToRead = value?.attributes?.Time_to_read;
                    const description = value?.attributes?.Hero?.Description;
                    const allText = description
                      .flatMap(
                        (block) =>
                          block.children?.map((child) => child.text) || []
                      )
                      .join(" ");
                    const truncatedText =
                      allText.split(" ").slice(0, 15).join(" ") + " ...";
                    const truncatedContent = [
                      {
                        type: "paragraph",
                        children: [{ text: truncatedText, type: "text" }],
                      },
                    ];
                    const categories = value?.attributes?.categories?.data;
                    const isLastIndex =
                      blogData?.data?.slice(
                        visibleRange.start,
                        visibleRange.end
                      ).length -
                        1 ===
                      index;
                    {
                      return (
                        <Grid key={index} size={12}>
                          <Grid container rowSpacing={1} columnSpacing={2}>
                            <Grid size={{ xs: 12, sm: 12, md: 4 }}>
                              <img
                                src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                                alt="blogs-image"
                                height={IsSM ? "350px" : "250px"}
                                className={styles.image}
                                onClick={() => navigate(url)}
                              />
                            </Grid>
                            <Grid size={{ xs: 12, sm: 12, md: 8 }}>
                              <Grid container rowSpacing={2}>
                                <Grid size={12}>
                                  <Grid container>
                                    {IsXS || IsSM ? (
                                      <Grid size={12}>
                                        <Typography
                                          className={styles.categoryItem}
                                        >
                                          {timeToRead} mins to read
                                        </Typography>
                                      </Grid>
                                    ) : (
                                      <Grid size={12}>
                                        {categories?.length > 0 &&
                                          categories?.map((item, index) => {
                                            return (
                                              <span
                                                className={styles.categoryItem}
                                                key={index}
                                              >
                                                {item?.attributes?.Title}
                                                {index <
                                                  categories.length - 1 && (
                                                  <span>
                                                    {" "}
                                                    &nbsp;&nbsp;•&nbsp;&nbsp;{" "}
                                                  </span>
                                                )}
                                              </span>
                                            );
                                          })}
                                      </Grid>
                                    )}
                                    <Grid size={12}>
                                      <Typography
                                        className={styles.blogTitle}
                                        onClick={() => navigate(url)}
                                      >
                                        {title}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {IsXS || IsSM ? (
                                  <Grid size={12}>
                                    <Grid container spacing={3}>
                                      <Grid>
                                        {author && (
                                          <Typography
                                            className={styles.author}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                          >
                                            <PenIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                            {author}
                                          </Typography>
                                        )}
                                      </Grid>

                                      <Grid>
                                        <Typography
                                          className={styles.author}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                          }}
                                        >
                                          <CalendarIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                          {date}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                                <Grid onClick={() => navigate(url)}>
                                  <BlocksRenderer
                                    content={
                                      IsXS || IsSM
                                        ? truncatedContent
                                        : description
                                    }
                                    blocks={{
                                      paragraph: ({ children }) => (
                                        <Typography
                                          className={styles.blogDescription}
                                        >
                                          {children}
                                        </Typography>
                                      ),
                                      heading: ({ children, level }) => (
                                        <Grid container mb={1}>
                                          <Grid size={12}>
                                            <Typography
                                              variant={`h${level || 1}`}
                                              className={styles.blogHeading}
                                              style={{
                                                fontSize:
                                                  [
                                                    "32px",
                                                    "28px",
                                                    "24px",
                                                    "20px",
                                                    "18px",
                                                    "16px",
                                                  ][level - 1] || "32px",
                                              }}
                                            >
                                              {children}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ),
                                      link: ({ children, url }) => (
                                        <Link
                                          to={url}
                                          style={{ color: "white" }}
                                          className={styles.blogDescription}
                                        >
                                          {children}
                                        </Link>
                                      ),
                                    }}
                                    modifiers={{
                                      bold: ({ children }) => (
                                        <span
                                          className={styles.blogDescriptionBold}
                                        >
                                          {children}
                                        </span>
                                      ),
                                      italic: ({ children }) => (
                                        <span
                                          className={styles.blogDescription}
                                        >
                                          {children}
                                        </span>
                                      ),
                                    }}
                                  />
                                </Grid>
                                {IsXS ? (
                                  <Grid size={12}>
                                    <Typography
                                      className={styles.blogDescription}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      onClick={() => navigate(url)}
                                    >
                                      Read more{" "}
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <ArrowIcon
                                          color={
                                            themeMode === "light"
                                              ? "#191919"
                                              : "#fbfbfb"
                                          }
                                        />
                                      </span>
                                    </Typography>
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                {IsXS || IsSM ? (
                                  ""
                                ) : (
                                  <Grid size={12}>
                                    <Grid container spacing={3}>
                                      <Grid>
                                        {author && (
                                          <Typography
                                            className={styles.author}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                          >
                                            <PenIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                            {author}
                                          </Typography>
                                        )}
                                      </Grid>

                                      <Grid>
                                        <Typography
                                          className={styles.author}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                          }}
                                        >
                                          <ReadTimeIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                          {timeToRead} mins to read
                                        </Typography>
                                      </Grid>
                                      <Grid>
                                        <Typography
                                          className={styles.author}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                          }}
                                        >
                                          <CalendarIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                          {date}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                            {!IsXS && !isLastIndex && (
                              <Grid size={12} my={4}>
                                <Divider sx={{ borderColor: "#ccc" }} />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
            </Grid>
          </Grid>
          <Grid
            size={12}
            sx={{ display: "flex", justifyContent: "center" }}
            mt={4}
          >
            {visibleRange.end < blogData.data?.length && (
              <Typography className={styles.loadMore} onClick={loadMoreBlogs}>
                Load More
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Blogs;
