import { combineReducers } from 'redux';
import { headerReducer } from "./headerReducer";
import { homeReducer } from './homeReducer';
import { servicesReducer } from './servicesReducer';
import { productsReducer } from './productsReducer';
import { footerReducer } from './footerReducer';
import { whoWeAreReducer } from './whoWeAreReducer';
import { blogReducer } from './blogReducer';

export const rootReducer = combineReducers({
    header: headerReducer,
    home: homeReducer,
    services: servicesReducer,
    products: productsReducer,
    footer: footerReducer,
    whoWeAre: whoWeAreReducer,
    blogs: blogReducer
})