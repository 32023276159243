import constants from "../Utils/constants";

const initialState = {
  isFooterLoading: false,
  footerData: [],
};

export const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_FOOTERS_REQUEST:
      return {
        ...state,
        isFooterLoading: true,
      };
    case constants.GET_FOOTERS_SUCCESS:
      return {
        ...state,
        isFooterLoading: false,
        footerData: action.payload,
      };
    case constants.GET_FOOTERS_FAILURE:
      return {
        ...state,
        isFooterLoading: false,
      };

    default:
      return state;
  }
};
