import React from "react";
import Grid from "@mui/material/Grid2";
import styles from "./CompanyInfo.module.css";
import { Box, Divider, Typography } from "@mui/material";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { AiOutlineProject } from "react-icons/ai";
import { HiUsers } from "react-icons/hi2";
import { BiHeadphone } from "react-icons/bi";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const CompanyInfo = () => {
   const { IsXS, IsSM, IsMD } = useBreakpoints();
  const data = [
    {
      icon: <MdOutlineEmojiEmotions />,
      value: "32",
      label: "Satisfied Clients",
    },
    {
      icon: <AiOutlineProject />,
      value: "15",
      label: "Projects",
    },
    {
      icon: <BiHeadphone />,
      value: "150000",
      label: "Line Of Code",
    },
    {
      icon: <HiUsers />,
      value: "11",
      label: "Man Power",
    },
  ];
  return (
    <Grid
      container
      justifyContent="center"
      className={styles.companyContainer}
      marginY={IsXS ? 10 :20}
      paddingY={6}
    >
      <Grid
        size={{ xs: 10, sm: 10, md: 11, lg: 8, xl: 7, xxl:5 }}
      >
        <Grid
          container
          columnSpacing={IsXS ? 5 : IsSM ? 20 : IsMD ? 7 : 9}
          rowSpacing={6}
        >
          {data.map((info, index) => {
            return (
              <Grid
                key={index}
                size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}
                className={styles.companyInfoSubContainer}
              >
                <Box className={styles.companyInfoIcon}>{info.icon}</Box>
                <Box className={styles.companyInfoContent}>
                  <Typography className={styles.companyInfoValue}>
                    <span className={styles.companyInfoText}>{info.value}</span>
                    <span className={styles.plusIcon}>+</span>
                  </Typography>
                  <Typography className={styles.label}>{info.label}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyInfo;
