import API from "../Utils/API";

export const getBlogs = (successCallback) => (dispatch) => {
  dispatch({ type: "GET_BLOGS_REQUEST" });
  API.get(
    "api/blogs?populate[0]=Hero&populate[1]=Hero.Media.Image&populate[2]=Hero.Internal_Link&populate[3]=Hero.custom_Url&populate[4]=Hero.Add_Column&populate[5]=Hero.Add_Column.Media.Image&populate[6]=Content&populate[7]=Media.Image&populate[8]=Call_To_Action&populate[9]=categories&sort=Publish_Date:desc&pagination[start]=0&pagination[limit]=-1&fields=Publish_Date&fields=Slug&fields=Author&fields=Time_to_read"
  )
    .then((response) => {
      dispatch({
        type: "GET_BLOGS_SUCCESS",
        payload: response.data,
      });
      successCallback(response.data)
    })
    .catch((error) => {
      dispatch({
        type: "GET_BLOGS_FAILURE",
        payload: error.response?.data || "An error occurred",
      });
    });
};

export const getBlogPreview = (slug, successCallback) => (dispatch) => {
  dispatch({ type: "GET_BLOG_PREVIEW_REQUEST" });
  API.get(
    `api/blogs?filters[Slug][$eq]=${slug}&populate[0]=Hero&populate[1]=Hero.Media.Image&populate[2]=Hero.Internal_Link&populate[3]=Hero.custom_Url&populate[4]=Hero.Add_Column&populate[5]=Hero.Add_Column.Media.Image&populate[6]=Content&populate[7]=Media.Image&populate[8]=Call_To_Action&populate[9]=categories&fields=Publish_Date&fields=Slug&fields=Author&fields=Time_to_read`
  )
    .then((response) => {
      dispatch({
        type: "GET_BLOG_PREVIEW_SUCCESS",
        payload: response.data,
      });
      successCallback && successCallback(response.data)
    })
    .catch((error) => {
      dispatch({
        type: "GET_BLOG_PREVIEW_FAILURE",
        payload: error.response?.data || "An error occurred",
      });
    });
};

