import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useBreakpoints = () => {
  const theme = useTheme();

  const IsXS = useMediaQuery(theme.breakpoints.only('xs'));

  const IsSM = useMediaQuery(theme.breakpoints.only('sm'));

  const IsMD = useMediaQuery(theme.breakpoints.only('md'));

  const IsLG = useMediaQuery(theme.breakpoints.only('lg'));

  const IsXL = useMediaQuery(theme.breakpoints.only('xl'));

  const IsXXL = useMediaQuery(theme.breakpoints.only('xxl'))

  return { IsXS, IsSM, IsMD, IsLG, IsXL, IsXXL };
};