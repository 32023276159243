import constants from "../Utils/constants";

const initialState = {
  isProductsLoading: false,
  productsData: [],
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductsLoading: true,
      };
    case constants.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductsLoading: false,
        productsData: action.payload,
      };
    case constants.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        isProductsLoading: false,
      };

    default:
      return state;
  }
};
