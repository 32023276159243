import React from "react";
import Grid from "@mui/material/Grid2";
import styles from "./ContactUs.module.css";
import { Typography } from "@mui/material";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const ContactUs = () => {
  const { IsXS, IsSM } = useBreakpoints();
  return (
    <Grid container justifyContent="center" id='contact_us'>
      <Grid
        size={{ xs: 12, sm: 11, md: 8, lg: 7, xl: 5, xxl:4 }}
        marginTop={IsXS ? 10: 20}
        className={styles.factorSubContainer}
        sx={{
          borderTopRightRadius: IsXS ? "" : "10px",
          borderTopLeftRadius: IsXS ? "" : "10px",
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid size={{ xs: 8, sm: 8, md: 8, lg: 6, xl: 6 }}>
            <Grid container>
              <Grid size={12}>
                <Typography className={styles.contactUsTitle}>
                  Contact Us
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography className={styles.contactUsHeading}>
                  Let’s make your Idea into Reality
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            size={{ xs: 4, sm: 4, md: 4, lg: 6, xl: 6 }}
            className={styles.contactUsContainer}
          >
            <div className={styles.textWrapper}>
              <Typography
                className={styles.contactUsText}
                onClick={() => {
                  window?.open(
                    `https://calendly.com/hello-defx/15min`,
                    "_self"
                  );
                }}
              >
                Let's Talk
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
