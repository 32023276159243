import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Footer.module.css";
import { Typography } from "@mui/material";
import { useTheme } from "../../ThemeContext";
import Logo from "../../assets/Logo2.svg";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";
import { useDispatch, useSelector } from "react-redux";
import { getFooters } from "../../Actions/footerAction";
import {
  Call,
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
} from "../../Utils/SvgImage";
import { FaDribbbleSquare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { themeMode, toggleTheme } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { IsXS, IsSM } = useBreakpoints();
  const { footerData, isFooterLoading } = useSelector((state) => state.footer);

  useEffect(() => {
    dispatch(getFooters());
  }, [dispatch]);

  // const hrefMapping = {
  //   "/home": "/",
  //   "/services": "/#services",
  //   "/products": "/#products",
  //   "/contact_us": "/#contact_us",
  // };

  // const internalLinkMapping = {
  //   "/home": "Home",
  //   "/services": "Services",
  //   "/products": "Products",
  //   "/projects": "Projects",
  //   "/whoWeAre": "Who We Are",
  //   "/blogs": "Blogs",
  //   "/contact_us": "Contact Us",
  // };
  const footerIcons = [
    {
      icon: <Facebook color={themeMode === "light" ? "#191919" : "#fbfbfb"} />,
      link: "https://www.facebook.com/defXhq/",
    },
    {
      icon: <Instagram color={themeMode === "light" ? "#191919" : "#fbfbfb"} />,
      link: "https://www.instagram.com/defx_hq/",
    },
    {
      icon: <LinkedIn color={themeMode === "light" ? "#191919" : "#fbfbfb"} />,
      link: "https://www.linkedin.com/company/80364263/admin/",
    },
    {
      icon: <FaDribbbleSquare style={{ width: "30", height: "30" }} />,
      link: "https://dribbble.com/DefX",
    },
  ];

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ borderTop: "1px solid #ccc" }}
    >
      <Grid
        size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }}
        paddingTop={10}
        paddingBottom={IsXS ? 2 : 10}
      >
        <Grid
          container
          justifyContent="center"
          rowSpacing={5}
          columnSpacing={5}
        >
          <Grid size={{ xs: 11, sm: 12, md: 4, lg: 4, xl: 4 }}>
            <Grid
              container
              rowSpacing={2}
              justifyContent={IsXS || IsSM ? "center" : ""}
            >
              <Grid>
                <img src={Logo} onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}/>
              </Grid>
              <Grid>
                <Typography
                  className={styles.footerText}
                  textAlign={IsXS || IsSM ? "center" : ""}
                >
                  As a comprehensive full-stack web design and development
                  agency, we leverage our extensive expertise and experience to
                  empower businesses across diverse domains, enabling the
                  establishment of a robust and impactful online presence.
                </Typography>
              </Grid>
              <Grid>
                <Grid
                  container
                  columnSpacing={3}
                  justifyContent={IsXS || IsSM ? "center" : ""}
                >
                  {footerIcons.map((item, index) => {
                    return (
                      <Grid size={2} key={index}>
                        <div
                          onClick={() => window.open(item.link, "_blank")}
                          style={{ cursor: "pointer" }}
                        >
                          {item.icon}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {footerData?.data?.length > 0 &&
            footerData?.data?.map((value, index) => {
              if (index === 0) return null;
              return (
                <Grid size={{ xs: 11, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <Grid
                    container
                    rowSpacing={2}
                    justifyContent={IsXS ? "center" : ""}
                  >
                    <Grid>
                      <Typography className={styles.footerTitle}>
                        {value?.attributes?.Title}
                      </Typography>
                    </Grid>
                    {value?.attributes?.Mapping_Links?.length > 0 &&
                      value?.attributes?.Mapping_Links?.map((link, index) => (
                        <Grid size={12} key={index}>
                          {link?.Custom_Url?.length > 0 && (
                            <Grid container spacing={1}>
                              {link?.Custom_Url?.map(
                                (customUrl, customUrlIndex) => {
                                  const completeUrl =
                                    customUrl?.Url?.startsWith("http://") ||
                                    customUrl?.Url?.startsWith("https://")
                                      ? customUrl?.Url
                                      : `http://${customUrl?.Url}`;

                                  return (
                                    <Grid
                                      key={customUrlIndex}
                                      size={12}
                                      className={
                                        IsXS ? styles.contactDetails : " "
                                      }
                                    >
                                      <Typography className={styles.footerText}>
                                        {customUrl?.Label}
                                      </Typography>
                                    </Grid>
                                  );
                                }
                              )}
                            </Grid>
                          )}
                          {/* {link?.Internal_Links?.length > 0 && (
                          <Grid container spacing={1}>
                            {link?.Internal_Links?.map(
                              (internalLink, internalLinkIndex) => {
                                const mappedHref =
                                  hrefMapping[internalLink?.Internal_Links] ||
                                  internalLink?.Internal_Links;
                                const mappedText =
                                  internalLinkMapping[
                                    internalLink?.Internal_Links
                                  ] || internalLink?.Internal_Links;

                                return (
                                  <Grid item xs={12} key={internalLinkIndex}>
                                    <Link href={mappedHref}>{mappedText}</Link>
                                  </Grid>
                                );
                              }
                            )}
                          </Grid>
                        )} */}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              );
            })}
          <Grid size={{ xs: 11, sm: 6, md: 4, lg: 4, xl: 4 }}>
            <Grid
              container
              rowSpacing={2}
              justifyContent={IsXS ? "center" : ""}
            >
              <Grid size={12}>
                <Typography
                  className={styles.footerTitle}
                  textAlign={IsXS ? "center" : ""}
                >
                  WHERE WE ARE
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography
                  className={styles.footerText}
                  textAlign={IsXS ? "center" : ""}
                >
                  B-203, Titanium Heights,Opp. Vodafone House,
                  Makarba-Prahladnagar,Ahmedabad. Gujarat, 380015.
                </Typography>
              </Grid>
              <Grid size={12} className={IsXS ? styles.contactDetails : " "}>
                <Typography className={styles.footerText}>
                  <span
                    style={{ marginRight: "10px" }}
                    className={IsXS ? styles.contactDetails : " "}
                  >
                    <Call color={themeMode === "light" ? "#191919" : "#fbfbfb"} />{" "}
                  </span>
                  <a
                    href="tel:+919409052511"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    +91 94090 52511
                  </a>
                </Typography>
              </Grid>
              <Grid size={12} className={IsXS ? styles.contactDetails : " "}>
                <Typography
                  className={styles.footerText}
                  component="a"
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=hello@defx.in"
                  target="_blank"
                  sx={{ textDecoration: "none", color: "inherit" }}
                >
                  <span
                    style={{ marginRight: "10px" }}
                    className={IsXS ? styles.contactDetails : " "}
                  >
                    <Mail color={themeMode === "light" ? "#191919" : "#fbfbfb"} />
                  </span>
                  hello@defx.in
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
