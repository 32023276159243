import constants from "../Utils/constants";

const initialState = {
  isHomeLoading: false,
  homeData: [],
};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_HOME_REQUEST:
      return {
        ...state,
        isHomeLoading: true,
      };
    case constants.GET_HOME_SUCCESS:
      return {
        ...state,
        isHomeLoading: false,
        homeData: action.payload,
      };
    case constants.GET_HOME_FAILURE:
      return {
        ...state,
        isHomeLoading: false,
      };

    default:
      return state;
  }
};
