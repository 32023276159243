import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Faq.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "../../ThemeContext";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const Faq = () => {
  const { themeMode } = useTheme();
   const { IsXS, IsSM, IsMD, IsLG, IsXL } = useBreakpoints();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const combinedItems = [
    {
      title: "What industries do you serve?",
      content:
        "We work with clients across a variety of industries, including retail, healthcare, finance, real estate, education, e-commerce etc.",
      panel: "panel1",
    },
    {
      title: "How long does it take to develop custom software?",
      content:
        "The timeline depends on the complexity and scope of the project. We provide detailed project timelines during the initial consultation.",
      panel: "panel2",
    },
    {
      title: "How much does custom software development cost?",
      content:
        "Costs vary based on project size, complexity, and features. We provide customised quotes after discussing your specific needs. ",
      panel: "panel3",
    },
    {
      title: "Do you provide ongoing maintenance and support for custom software?",
      content:
        "Yes, we offer maintenance and support packages to ensure the smooth functioning and continuous improvement of your software.",
      panel: "panel4",
    },
    {
      title: "Do you provide website hosting?",
      content:
        "Yes, we can assist you with website hosting and domain registration.",
      panel: "panel5",
    },
    {
      title: "Do you provide project timelines and milestones?",
      content:
        "Yes, we provide detailed project timelines and milestones to ensure the project stays on track and within budget.",
      panel: "panel6",
    },
    {
      title: "How do you ensure the security of our data and applications?",
      content:
        "We implement industry-standard security measures and protocols, including encryption, data backup, and regular security audits, to ensure data safety.",
      panel: "panel7",
    },
    {
      title: "What technologies do you use for software development?",
      content:
        "We utilize a range of technologies such as  React, React Native, Nodejs, Angular, Postgres, MongoDB, MySQL etc. depending on the project requirements. ",
      panel: "panel8",
    },
    {
      title: "What is your UI/UX design process?",
      content:
        "Our UI/UX design process includes user research, wire framing, prototyping, user testing, and iterative design improvements to ensure the product meets the needs of both the users and the business.",
      panel: "panel9",
    },
    {
      title: "What design tools do you use?",
      content:
        "We utilize a variety of industry-leading design tools such as Figma, Canva, Sketch. ",
      panel: "panel10",
    },
  ];

  return (
    <Grid
      container
      justifyContent="center"
      className={styles.container}
      marginTop={IsXS ? 10: 20}
    >
      <Grid
        size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }}
        className={styles.testimonialsContainer}
        paddingY={2}
      >
        <Grid
          container
          justifyContent="center"
          rowSpacing={2}
          columnSpacing={2}
        >
          <Grid size={12} className={styles.faqContainer}>
            <div className={styles.textWrapper}>
              <Typography className={styles.faqText}>FAQ's</Typography>
            </div>
          </Grid>
          <Grid size={12}>
            <Typography className={styles.faqHeading}>
              RAPID ASSISTANCE
            </Typography>
          </Grid>
          <Grid size={{xs:12, sm:12, md:11}}>
            <Grid container columnSpacing={2} rowSpacing={2} justifyContent='center'>
              {combinedItems.map((item, index) => (
                <Grid size={{xs: 12, sm: 12, md: 9, lg: 11, xl: 9, xxl:10}} key={index}>
                  <div className={styles.gradiantColor}>
                    <Accordion
                      key={item.panel}
                      expanded={expanded === item.panel}
                      onChange={handleChange(item.panel)}
                      sx={{
                        background: "transparent",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: themeMode === "light" ? "#191919" : "#fbfbfb", 
                              }}
                            />
                          }
                        aria-controls={`${item.panel}-content`}
                        id={`${item.panel}-header`}
                      >
                        <Typography className={styles.faqTitle} sx={{
                            color: themeMode === "light" ? "#191919" : "#fbfbfb",
                          }}>
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          className={styles.faqContent}
                          sx={{
                            color: themeMode === "light" ? "#191919" : "#fbfbfb",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default Faq;
