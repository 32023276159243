import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./WhoWeAre.module.css";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "../../ThemeContext";
import whoweare from "../../assets/whoweare.jpg";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";
import rubyonrails from "../../assets/rubyonrails.svg";
import awsicon from "../../assets/awsicon.svg";
import django from "../../assets/django.svg";
import angularjs from "../../assets/angularjs.svg";
import nextjs from "../../assets/nextjs.svg";
import expressjs from "../../assets/expressjs.svg";
import reactjs from "../../assets/reactjs.svg";
import remixjs from "../../assets/remixjs.svg";
import vuejs from "../../assets/vuejs.svg";
import tailwind from "../../assets/tailwind.svg";
import firebase from "../../assets/firebase.svg";
import mongodb from "../../assets/mongodb.svg";
import redis from "../../assets/redis.svg";
import postgresql from "../../assets/postgresql.svg";
import dynamodb from "../../assets/dynamodb.svg";
import jenkins from "../../assets/jenkins.svg";
import githubactions from "../../assets/githubactions.svg";
import kubernetes from "../../assets/kubernetes.svg";
import docker from "../../assets/docker.svg";
import playwright from "../../assets/playwright.svg";
import googlecloud from "../../assets/googlecloud.svg";
import herokuicon from "../../assets/herokuicon.svg";
import digitalocean from "../../assets/digitalocean.svg";
import flyio from "../../assets/flyio.svg";
import python from "../../assets/python.svg";
import ruby from "../../assets/ruby.svg";
import nodejs from "../../assets/nodejs.svg";
import java from "../../assets/java.svg";
import php from "../../assets/php.svg";
import { useDimensions } from "../../CommonMethods";
import { LLMS, NextJs, OpenAi } from "../../Utils/SvgImage";

const WhoWeAre = () => {
  const { IsXS, IsSM, IsMD, IsLG, IsXL, IsXXL } = useBreakpoints();
  const { themeMode } = useTheme();
  const { width } = useDimensions();

  const card = [
    {
      title: "People",
      description:
        "DEFX is more than just a web and app development company; we're a tight-knit team dedicated to creating a workplace that inspires creativity and collaboration every day. Our philosophy emphasizes agility, data-driven insights, and a strong focus on empowering our employees. With a team of 11 talented professionals, we excel in delivering tailored digital solutions that drive innovation and exceed client expectations. Join us at DEFX, where passion meets expertise, and together, we shape the future of digital excellence.",
    },
    {
      title: "Workplace Experience",
      description:
        "At DEFX, our team is dedicated to crafting world-class workspaces that inspire collaboration. With a service-oriented and collaborative approach, we prioritize internal and external relationships, ensuring safe, secure, and inclusive environments for all our employees.",
    },
  ];

  const technologies = [
    {
      title: "Ruby on Rails",
      image: <img src={rubyonrails} />,
    },
    {
      title: "Django",
      image: <img src={django} />,
    },
    {
      title: "AngularJS",
      image: <img src={angularjs} />,
    },
    {
      title: "NextJS",
      image: <NextJs color={themeMode === "light" ? "#191919" : "#fbfbfb"} />,
    },
    {
      title: "Express JS",
      image: <img src={expressjs} />,
    },
    {
      title: "ReactJS",
      image: <img src={reactjs} />,
    },
    {
      title: "RemixJS",
      image: <img src={remixjs} />,
    },
    {
      title: "React Native",
      image: <img src={reactjs} />,
    },
    {
      title: "VueJS",
      image: <img src={vuejs} />,
    },
    {
      title: "Tailwind",
      image: <img src={tailwind} />,
    },
    {
      title: "Firebase",
      image: <img src={firebase} />,
    },
    {
      title: "MongoDB",
      image: <img src={mongodb} />,
    },
    {
      title: "Redis",
      image: <img src={redis} />,
    },
    {
      title: "PostgreSQL",
      image: <img src={postgresql} />,
    },
    {
      title: "Dynamo DB",
      image: <img src={dynamodb} />,
    },
    {
      title: "Jenkins",
      image: <img src={jenkins} />,
    },
    {
      title: "Github Actions",
      image: <img src={githubactions} />,
    },
    {
      title: "Kubernetes",
      image: <img src={kubernetes} />,
    },
    {
      title: "Docker",
      image: <img src={docker} />,
    },
    {
      title: "Playwright",
      image: <img src={playwright} />,
    },
    {
      title: "AWS",
      image: <img src={awsicon} />,
    },
    {
      title: "Google Cloud",
      image: <img src={googlecloud} />,
    },
    {
      title: "Heroku",
      image: <img src={herokuicon} />,
    },
    {
      title: "Digital Ocean",
      image: <img src={digitalocean} />,
    },
    {
      title: "Fly.io",
      image: <img src={flyio} />,
    },
    {
      title: "Python",
      image: <img src={python} />,
    },
    {
      title: "Ruby",
      image: <img src={ruby} />,
    },
    {
      title: "NodeJS",
      image: <img src={nodejs} />,
    },
    {
      title: "Java",
      image: <img src={java} />,
    },
    {
      title: "PHP",
      image: <img src={php} />,
    },
    {
      title: "Open AI",
      image: <OpenAi color={themeMode === "light" ? "#191919" : "#fbfbfb"} />,
    },
    {
      title: "LLMS",
      image: <LLMS color={themeMode === "light" ? "#191919" : "#fbfbfb"} />,
    },
  ];

  return (
    <Grid container justifyContent="center">
      <Grid
        size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl: 5 }}
        paddingY={8}
      >
        <Grid container justifyContent="center">
          <Grid className={styles.titleContainer}>
            <Typography className={styles.whoWeAreTitle}>Who We Are</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={styles.imageContainer}
          sx={{ backgroundImage: `url(${whoweare}) !important` }}
        >
          <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl: 5 }}>
            <Typography className={styles.whoWeAreDescription}>
              At DEFX, we are a dynamic team of innovators specializing in web
              and app development, project management, and product solutions.
              With a passionate team of 11 professionals, we leverage
              cutting-edge technologies to craft bespoke digital experiences
              that drive business growth and exceed client expectations. Our
              commitment to excellence, combined with extensive industry
              expertise, ensures that we deliver scalable solutions tailored to
              meet the unique needs of our diverse clientele. Partner with DEFX
              and embark on a journey of digital transformation and success.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {IsXS || IsSM ? (
        ""
      ) : (
        <Grid
          size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl: 5 }}
          paddingY={8}
        >
          <Grid container justifyContent="center" rowSpacing={5}>
            <Grid>
              <Typography className={styles.teamHighlightsTitle}>
                TEAM HIGHLIGHTS
              </Typography>
            </Grid>
            <Grid size={11}>
              <Grid container columnSpacing={10}>
                {card.map((item, index) => {
                  return (
                    <Grid
                      size={6}
                      key={index}
                      className={styles.teamHighlightsContainer}
                    >
                      <Grid container rowSpacing={4} mt={2}>
                        <Grid>
                          <Typography className={styles.teamHighlightsHeading}>
                            {item.title}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            className={styles.teamHighlightsdescription}
                          >
                            {item.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid size={12}>
        <Grid container justifyContent="center">
          <Grid
            size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl: 5 }}
            marginTop={IsXS || IsSM ? 8 : ""}
          >
            <Grid container justifyContent="center" rowSpacing={3}>
              <Grid size={12} className={styles.techStackContainer}>
                <div className={styles.textWrapper}>
                  <Typography className={styles.techStackText}>
                    Tech Stack
                  </Typography>
                </div>
              </Grid>
              <Grid size={12}>
                <Typography className={styles.teamHighlightsTitle}>
                  TECHNOLOGIES WE USE
                </Typography>
              </Grid>
              <Grid size={12} mt={4}>
                <Grid
                  container
                  rowSpacing={
                    width > 2100
                      ? 10
                      : width > 1920
                      ? 10
                      : width > 1750
                      ? 12
                      : width > 1585
                      ? 8
                      : width > 1339
                      ? 6
                      : width > 1280
                      ? 3
                      : width > 900
                      ? 10
                      : width > 600
                      ? 7
                      : width > 200
                      ? 2
                      : ""
                  }
                  columnSpacing={
                    width > 2559
                      ? 8
                      : width > 2538
                      ? 18
                      : width > 2100
                      ? 16
                      : width > 1920
                      ? 10
                      : width > 1750
                      ? 12
                      : width > 1585
                      ? 9
                      : width > 1339
                      ? 6
                      : width > 1280
                      ? 3
                      : width > 900
                      ? 10
                      : width > 600
                      ? 7
                      : width > 200
                      ? 2
                      : ""
                  }
                  justifyContent="center"
                >
                  {technologies.map((item, index) => {
                    return (
                      <Grid
                        className={styles.technologiesContainer}
                        width={140}
                        // width={width < 366 ? 94 : width < 494 ? 101 : 140}
                        // sx={{ padding: width < 494 ? "15px 2px" : "35px 2px" }}
                        // padding={width < 494 ? "25px 2px" : "35px 2px"}
                        // size={{ xs: 4, sm: 3, md: 2.4, lg: 2.4, xl: 2.4 }}
                        key={index}
                      >
                        <Grid container rowSpacing={2}>
                          <Grid size={12}>{item.image}</Grid>
                          <Grid size={12}>
                            <Typography className={styles.technologiesText}>
                              {item.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl: 5 }} mt={8}>
        <Grid container justifyContent="center">
          <Grid className={styles.talkToExpertContainer}>
            <Typography
              className={styles.talkToExpertText}
              onClick={() => {
                window?.open(`https://calendly.com/hello-defx/15min`, "_self");
              }}
            >
              Talk to an expert
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhoWeAre;
