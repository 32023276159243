import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Copyright.module.css";
import { Typography } from "@mui/material";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const Copyright = () => {
const { IsXS, IsSM } = useBreakpoints();
  return (
    <Grid
      container
      justifyContent="center"
      className={styles.copyrightContainer}
    >
      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid size={{ xs: 12, sm: 11, md: 6, lg: 6, xl: 6 }}>
            <Typography className={styles.copyrightTitle} textAlign={IsXS || IsSM ? 'center':''}>
              © Copyright DEFX. All Rights Reserved
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 11, md: 6, lg: 6, xl: 6 }}>
            <Grid container justifyContent='space-between'>
              <Grid>
                <Typography className={styles.copyrightTitle}>
                  GoodFirms ★ 4.2
                </Typography>
              </Grid>
              <Grid>
                <Typography className={styles.copyrightTitle}>
                  Clutch ★ 4.2
                </Typography>
              </Grid>
              <Grid>
                <Typography className={styles.copyrightTitle}>
                  Google ★ 4.2
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Copyright;
