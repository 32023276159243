import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "../../ThemeContext";
import Grid from "@mui/material/Grid2";
import styles from "./Home.module.css";
import { getHome } from "../../Actions/homeAction";
import { Box, Button, CircularProgress, keyframes, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const Home = () => {
  const navigate = useNavigate();
  const { themeMode, toggleTheme } = useTheme();
  const dispatch = useDispatch();
  const { IsXS, IsSM } = useBreakpoints();
  const { homeData, isHomeLoading } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getHome());
  }, [dispatch]);

  const [currentWord, setCurrentWord] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % 3); 
    }, 3000); 

    return () => clearInterval(interval); 
  }, []);

  if (isHomeLoading) {
      return (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

  return (
    <Grid container justifyContent="center">
      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }} paddingTop={12}>
        <Grid container justifyContent="center">
          <Grid className={styles.navContainer}>
            {homeData?.data?.length > 0 &&
              homeData?.data?.map((home, index) => {
                const homeWords = home?.attributes?.Title;
                const words = homeWords.split(".").filter(Boolean).map((word) => word.trim());
                const heroData = home?.attributes?.Hero?.Title;
                const heroDescription =
                  home?.attributes?.Hero?.Description[0]?.children[0]?.text;
                const heroButton =
                  home?.attributes?.Content?.Custom_Url[0]?.Label;
                const heroButtonUrl =
                  home?.attributes?.Content?.Custom_Url[0]?.Url;
                return (
                  <Grid
                    container
                    key={index}
                    justifyContent="center"
                    rowSpacing={5}
                  >
                    <Grid size={12}>
                      <Grid container justifyContent="center" spacing={1}>
                        {words.map((word, i) => (
                          <Grid
                            key={i}
                            className={
                              currentWord === i ? styles.keyframes : ""
                            }
                            sx={{
                              fontFamily:'Circular-400',
                              fontSize: IsXS ? '30px':'45px',
                              textAlign: "center",
                              opacity: currentWord === i ? 1 : 0.2,
                              background:
                                "linear-gradient(to right, #ec53b0, #7d7cf9)",
                              WebkitBackgroundClip: "text",
                              color: currentWord === i ? "transparent" : "",
                              transition: "opacity 2s ease-in-out, color 2s ease-in-out", 
                            }}
                          >
                               {`${word.trim()}.`}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography className={styles.heroData}>
                        {heroData}
                      </Typography>
                    </Grid>

                    <Grid size={12}>
                      <Typography className={styles.heroDescription}>
                        {heroDescription}
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography
                        className={styles.entrustData}
                        sx={{
                          background: themeMode === "light" ? "#fbfbfb" : "#191919",
                          color: themeMode === "light" ? "#191919" : "#fbfbfb",
                        }}
                        onClick={() => {
                          window?.open(`http://${heroButtonUrl}`, "_self");
                        }}
                      >
                        {heroButton}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AnimatedText = ({ text }) => {
  useEffect(() => {
    const pElement = document.querySelector("p[data-linear-wipe]");
    if (pElement) {
      const words = text
        .split(" ")
        .map((word, index) => {
          const delay = `${index * 4}s`; // Adjust this delay for sequential animation
          return `<span style="animation-delay: ${delay};">${word}</span>`;
        })
        .join(" ");
      pElement.innerHTML = words;
    }
  }, [text]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid>
        <p data-linear-wipe className={styles.animatedText}>
          {text}
        </p>
      </Grid>
    </Grid>
  );
};

export default Home;
