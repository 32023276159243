import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Services.module.css";
import { Typography } from "@mui/material";
import { useTheme } from "../../ThemeContext";
import csd from '../../assets/csd.svg';
import cms from '../../assets/cms.svg';
import erp from '../../assets/erp.svg';
import mad from '../../assets/mad.svg';
import ms from '../../assets/ms.svg';
import qa from '../../assets/qa.svg';
import spd from '../../assets/spd.svg';
import spr from '../../assets/spr.svg';
import tc from '../../assets/tc.svg';
import uiux from '../../assets/ui_ux.svg';
import ecom from '../../assets/ui_ux.svg';
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const Services = () => {
  const { IsXS,IsMD, IsLG, IsXL, IsXXL } = useBreakpoints()
  const { themeMode, toggleTheme } = useTheme();

  const servicesData = [
    { title: "Custom Software Development", image: csd},
    { title: "UI/UX Design", image: uiux },
    { title: "QA & Software Testing", image: qa },
    { title: "Software Product Development", image: spd },
    { title: "Mobile Application Development", image: mad },
    { title: "Maintenance & Support", image: ms },
    { title: "Software Project Recovery", image: spr },
    { title: "CMS Solutions", image: cms },
    { title: "ERP Solutions", image: erp },
    { title: "eCommerce Solutions", image: ecom },
    { title: "Technology Consulting", image: tc },
];

  return (
    <Grid container justifyContent="center" id="services">
      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }} paddingY={IsXS ? 10 :20}>
        <Grid
          container
          justifyContent="center"
          rowSpacing={2}
          columnSpacing={2}
        >
          <Grid>
            <Typography className={styles.serviceTitle}>
              OUR SERVICES
            </Typography>
          </Grid>
          <Grid size={12}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              justifyContent="center"
            >
              {servicesData?.map((services, index) => {
                return (
                  <Grid
                    key={index}
                    size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      rowSpacing={2}
                      className={styles.imageContainer}
                    >
                      <Grid>
                        <img
                          src={services.image}
                        />
                      </Grid>
                      <Grid
                        size={12}
                        sx={{
                          background: themeMode === "light" ? "#fbfbfb" : "#191919",
                          color: themeMode === "light" ? "#191919" : "#fbfbfb",
                          borderBottomRightRadius: "3px",
                          borderBottomLeftRadius: "3px",
                        }}
                      >
                        <Typography className={styles.imageText}>
                          {services.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Services;
