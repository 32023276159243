import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./Products.module.css";
import { Button, Typography } from "@mui/material";
import { getProducts } from "../../Actions/productsAction";
import { useDispatch, useSelector } from "react-redux";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const Products = () => {
  const { IsXS, IsSM } = useBreakpoints();
  const dispatch = useDispatch();

  const { productsData, isProductsLoading } = useSelector(
    (state) => state.products
  );

  // const learnMoreUrls = {
  //   "product1Id": "https://get-prest.com/",
  //   "product2Id": "https://getsamaj.com/",
  // };

  const getLearnMoreUrl = (productId) => {
    switch (productId) {
      case 1:
        return "https://get-prest.com/";
      case 2:
        return "https://getsamaj.com/";
      default:
        return null; // Or a default URL
    }
  };

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <Grid container justifyContent="center" id="products">
      <Grid
        size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }}
        marginTop={IsXS ? 10: IsSM ? 20 : ""}
      >
        <Grid container rowSpacing={5} columnSpacing={2}>
          <Grid size={12}>
            <Typography
              className={styles.productsTitle}
              textAlign={IsXS || IsSM ? "center" : "start"}
            >
              CORE PRODUCTS SHOWCASE
            </Typography>
          </Grid>
          <Grid size={12}>
            {/* <Grid container rowSpacing={10}>
              {productsData?.data?.map((item, index) => {
                const imageUrl =
                  item?.attributes?.Hero?.Media[0]?.Image?.data?.attributes
                    ?.url;
                const title = item?.attributes?.Hero?.Title;
                const description =
                  item?.attributes?.Hero?.Description[0]?.children[0]?.text;
                  const learnMoreUrl =
                    item?.attributes?.Content[0]?.Custom_Url[0]?.Url;
                return (
                  <Grid>
                    <Grid container rowSpacing={2} columnSpacing={4}>
                      {index % 2 === 0 ? (
                        <>
                          <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                            <img
                              src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                              alt="Products Image"
                              style={{
                                width: "100%",
                                height:'100%',
                                boxShadow: "-10px 10px",
                              }}
                            />
                          </Grid>
                          <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} className={styles.itemTextContainer}>
                            <Grid container rowSpacing={2}>
                              <Grid>
                                <Typography
                                  className={styles.productsItemTitle}
                                >
                                  {title}
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  className={styles.productsItemDescription}
                                >
                                  {description}
                                </Typography>
                              </Grid>
                              <Grid size={12}>
                                <Grid container justifyContent="end">
                                  <Button className={styles.gradiantColor} 
                                //   onClick={() => window.open(`${learnMoreUrl}`, '_self')}
                                    >
                                    Learn More
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} className={styles.itemTextContainer}>
                            <Grid container rowSpacing={2}>
                              <Grid>
                                <Typography
                                  className={styles.productsItemTitle}
                                >
                                  {title}
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  className={styles.productsItemDescription}
                                >
                                  {description}
                                </Typography>
                              </Grid>
                              <Grid size={12}>
                              <Grid container>
                                  <Button className={styles.gradiantColor} onClick={() => window.open(learnMoreUrl, '_self')}>
                                    Learn More
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                            <img
                              src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                              alt="Products Image"
                              style={{ width: "100%",height:'100%', boxShadow: "10px 10px" }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid> */}
            <Grid container spacing={5}>
              {productsData?.data?.length > 0 &&
                productsData?.data?.map((item, index) => {
                  const imageUrl =
                    item?.attributes?.Hero?.Media[0]?.Image?.data?.attributes
                      ?.url;
                  const title = item?.attributes?.Hero?.Title;
                  const description =
                    item?.attributes?.Hero?.Description[0]?.children[0]?.text;
                  // const learnMoreUrl = item?.attributes?.Hero?.LearnMoreUrl;
                  const productId = item?.id;
                  const learnMoreUrl = getLearnMoreUrl(productId);

                  return (
                    <Grid
                      container
                      size={12}
                      spacing={4}
                      direction={{
                        xs: "column",
                        sm: "column",
                        md: index % 2 === 0 ? "row" : "row-reverse",
                      }}
                      key={index}
                    >
                      <Grid
                        size={{ xs: 12, md: 6 }}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                          alt="Products Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            boxShadow:
                              IsXS || IsSM
                                ? ""
                                : index % 2 === 0
                                ? "-8px 8px"
                                : "8px 8px",
                          }}
                          className={styles.productGradient}
                        />
                      </Grid>

                      <Grid
                        size={{ xs: 12, md: 6 }}
                        className={styles.itemTextContainer}
                      >
                        <Grid container spacing={2}>
                          <Grid size={12}>
                            <Typography
                              className={styles.productsItemTitle}
                              textAlign={IsXS || IsSM ? "center" : "start"}
                            >
                              {title}
                            </Typography>
                          </Grid>
                          <Grid size={12}>
                            <Typography
                              className={styles.productsItemDescription}
                              textAlign={IsXS || IsSM ? "center" : "start"}
                            >
                              {description}
                            </Typography>
                          </Grid>
                          <Grid size={12}>
                            <Grid
                              container
                              justifyContent={
                                IsXS || IsSM
                                  ? "center"
                                  : index % 2 === 0
                                  ? "flex-end"
                                  : ""
                              }
                            >
                              <Typography className={styles.entrustButton} onClick={() => {
                                  if (learnMoreUrl) {
                                    window.open(learnMoreUrl, "_self");
                                  }
                                }}>Learn More</Typography>
                              {/* <Button
                                className={styles.gradiantColor}
                                
                              >
                                
                              </Button> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Products;
