import API from "../Utils/API";

export const getFooters = () => (dispatch) => {
  dispatch({ type: "GET_FOOTERS_REQUEST" });
  API.get('api/footers?populate[Mapping_Links][populate]=*')
    .then((response) => {
      dispatch({
        type: "GET_FOOTERS_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_FOOTERS_FAILURE",
        payload: error.response?.data || "An error occurred",
      });
    });
};
