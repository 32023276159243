import React from "react";
import Grid from "@mui/material/Grid2";
import styles from "./WhatWeDo.module.css";
import { Divider, Typography } from "@mui/material";
import { PersonIcon } from "../../Utils/SvgImage";
import { useTheme } from "../../ThemeContext";

const WhatWeDo = () => {
  const { themeMode, toggleTheme } = useTheme();

  const data = [
    {
      title: "Expert Design & Development",
      description:
        "We excel in expert design and development. Our dedicated professionals deliver innovative solutions, ensuring excellence in every project.",
    },
    {
      title: "Full Stack Web & Mobile Solutions",
      description:
        "We offer comprehensive full stack web and app solutions. Our team handles everything from front-end to back-end development, ensuring seamless integration and top-quality results.",
    },
    {
      title: "Elevating Business Presence",
      description:
        "Elevate your brand with our tailored services: brand enhancement, targeted marketing, and impactful digital strategies. Trust us to amplify your visibility.",
    },
    {
      title: "Proficiency in Scaling Strategies",
      description:
        "At DEFX, our experts optimize operations, ensuring a seamless transition to new heights. Trust us to drive your success with strategic scalability aligned with your goals.",
    },
  ];
  return (
    <Grid container justifyContent="center">
      <Grid size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl:5 }}>
        <Grid container justifyContent="center" rowSpacing={2}>
          <Grid size={12}>
            <Typography className={styles.whyChooseUs}>
              Why Choose Us?
            </Typography>
          </Grid>
          <Grid>
            <Typography className={styles.whatWeTitle}>WHAT WE DO</Typography>
          </Grid>
          <Grid>
            <Grid container rowSpacing={5} columnSpacing={10}>
              {data.map((text, index) => {
                return (
                  <Grid
                    key={index}
                    size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}
                    className={styles.whatWeDoContentContainer}
                  >
                    <Grid container rowSpacing={2} >
                      <PersonIcon
                        color={themeMode === "light" ? "#191919" : "#fbfbfb"}
                      />

                      <Grid size={9} ml={2} >
                        <Grid container rowSpacing={2} mt={0.7}>
                          <Grid>
                            <Typography className={styles.whatWeDoTitle}>
                              {text.title}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography className={styles.whatWeDescription}>
                              {text.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhatWeDo;
