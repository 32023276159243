import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import styles from "./IndustryList.module.css";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";

const IndustryList = () => {
  const { IsXS, IsSM, IsMD, IsLG, IsXL } = useBreakpoints();
  const card = [
    "Instagram",
    "Google",
    "Microsoft",
    "LinkedIn",
    "IBM",
    "Infosys",
    "Walmart",
    "Aditya Birla Group",
    "Procter and Gamble",
  ];

  return (
    <Grid container justifyContent="center">
      <Grid size={12} marginTop={IsXS ? 10: 20}>
        <Grid container rowSpacing={5} columnSpacing={2}>
          <Grid size={12} className={styles.tickcerContainer}>
            <div className={styles.stockTicker}>
              <ul>
                {card.map((item, index) => (
                  <li key={index}>
                    <span className={styles.title}>{item}</span>
                  </li>
                ))}
              </ul>
              <ul
                aria-hidden="true"
                style={{
                  marginLeft:
                    IsXS || IsSM ? "" : IsMD ? "10px" : IsLG ? "60px" : "120px",
                }}
              >
                {card.map((item, index) => (
                  <li key={index}>
                    <span className={styles.title}>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IndustryList;
